import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, FloatingLabel, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ContactForm = function ({ data }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    useState(undefined);

  const onSubmit = async (formData) => {
    const { name, email, subject, message } = formData;
    const templateParams = {
      name,
      email,
      subject,
      message,
    };

    const response = await fetch(`https://api.soonerprod.com/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(templateParams),
    });
    const result = await response.json();
    reset();
    return setIsSuccessfullySubmitted(result.success);
  };

  const successAlert = (
    <div className="alert alert-success mb-5" role="alert">
      <h5 className="alert-heading mb-3">{data.successAlert.title}</h5>
      <p className="mb-0">{data.successAlert.content}</p>
    </div>
  );

  const errorAlert = (
    <div className="alert alert-danger mb-5" role="alert">
      <h5 className="alert-heading mb-3">{data.errorAlert.title}</h5>
      <p className="mb-0">{data.errorAlert.content}</p>
    </div>
  );

  const form = (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FloatingLabel controlId="name" label={data.name.label} className="mb-3">
        <Form.Control
          type="text"
          name="name"
          placeholder={data.name.label}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("name", {
            required: { value: true, message: data.name.validation },
          })}
          disabled={isSubmitting}
        />
      </FloatingLabel>
      {errors.name && (
        <div className="alert alert-danger py-2" role="alert">
          {errors.name.message}
        </div>
      )}

      <FloatingLabel
        controlId="email"
        label={data.email.label}
        className="mb-3"
      >
        <Form.Control
          type="email"
          name="email"
          placeholder={data.email.label}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("email", {
            required: true,
            pattern:
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: data.email.validation,
          })}
          disabled={isSubmitting}
        />
      </FloatingLabel>
      {errors.email && (
        <div className="alert alert-danger py-2" role="alert">
          {errors.email.message}
        </div>
      )}

      <FloatingLabel
        controlId="subject"
        label={data.subject.label}
        className="mb-3"
      >
        <Form.Control
          type="text"
          name="subject"
          placeholder={data.subject.label}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("subject", {
            required: { value: true, message: data.subject.validation },
            maxLength: {
              value: 75,
              message: data.subject.maxLengthValidation,
            },
          })}
          disabled={isSubmitting}
        />
      </FloatingLabel>
      {errors.subject && (
        <div className="alert alert-danger py-2" role="alert">
          {errors.subject.message}
        </div>
      )}

      <FloatingLabel controlId="message" label={data.message.label}>
        <Form.Control
          as="textarea"
          className="mb-3"
          style={{ height: "250px" }}
          name="message"
          placeholder={data.message.label}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("message", {
            required: { value: true, message: data.message.validation },
          })}
          disabled={isSubmitting}
        />
      </FloatingLabel>
      {errors.message && (
        <div className="alert alert-danger py-2" role="alert">
          {errors.message.message}
        </div>
      )}

      <Button variant="primary" type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="spinner-border spinner-border-sm me-2" />
        ) : (
          <i className="fas fa-paper-plane me-2" />
        )}
        {data.send}
      </Button>
    </Form>
  );

  return (
    <>
      {isSuccessfullySubmitted && successAlert}
      {isSuccessfullySubmitted === false && errorAlert}
      {isSuccessfullySubmitted === undefined && form}
    </>
  );
};

ContactForm.propTypes = {
  data: PropTypes.shape({
    postUrl: PropTypes.string.isRequired,
    name: PropTypes.oneOfType([PropTypes.object]).isRequired,
    email: PropTypes.oneOfType([PropTypes.object]).isRequired,
    subject: PropTypes.oneOfType([PropTypes.object]).isRequired,
    message: PropTypes.oneOfType([PropTypes.object]).isRequired,
    send: PropTypes.string.isRequired,
    successAlert: PropTypes.oneOfType([PropTypes.object]).isRequired,
    errorAlert: PropTypes.oneOfType([PropTypes.object]).isRequired,
  }).isRequired,
};

export default ContactForm;
