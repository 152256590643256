import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Hero from "../components/Hero";
import ContactForm from "../components/ContactForm";

const ContactTemplate = function ({ data: { appSetting, contact } }) {
  return (
    <Layout appSettingLocale={appSetting}>
      <Hero
        title={contact.hero?.title ? contact.hero.title : contact.title}
        description={contact.hero?.description && contact.hero.description}
        background={
          contact.hero?.background &&
          contact.hero.background.localFile.publicURL
        }
      />
      <section className="ttm-row">
        <Container>
          <Row>
            <Col lg={7} className="mb-5 mb-lg-0">
              <ContactForm data={contact.form} />
            </Col>
            <Col lg={5} className="ps-lg-5">
              {parse(contact.content.data.content)}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

ContactTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ContactTemplate;

export const Head = ({
  location,
  data: {
    contact: { title, localizations },
  },
}) => (
  <Seo
    title={title}
    localizations={localizations.data}
    pathname={location.pathname}
  />
);

export const pageQuery = graphql`
  query ($id: String!, $locale: String!) {
    contact: strapiContact(id: { eq: $id }) {
      locale
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      title

      form {
        postUrl
        name {
          label
          validation
        }
        email {
          label
          validation
        }
        subject {
          label
          validation
          maxLengthValidation
        }
        message {
          label
          validation
        }
        send
        successAlert {
          title
          content
        }
        errorAlert {
          content
          title
        }
      }
      content {
        data {
          content
        }
      }
    }
    appSetting: strapiAppSetting(locale: { eq: $locale }) {
      headerNav {
        strapi_json_value {
          label
          url
          subMenu {
            label
            url
          }
        }
      }
      footerNav {
        strapi_json_value {
          label
          subMenu {
            label
            url
          }
        }
      }
      footerContactTitle
      footerContactContent {
        data {
          footerContactContent
        }
      }
      copyrightLinks {
        strapi_json_value {
          label
          url
        }
      }
    }
  }
`;
